import {
    ControlledCheckbox,
    ErrorBox,
    StyledLink,
    createProcessGuidanceButtons,
} from '@ten-netzkundenportal/ui-components';
import {
    CombinedUploadState,
    DataLessFileTransfer,
    SimpleDocumentUploadForm,
    useContainerClientWrapper,
} from '@ten-netzkundenportal/ui-document-upload';
import React, { useCallback, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import appConfig from '../../../app.config';
import useApi from '../../../hooks/useApi';
import { OFFER_REVISITED_DOCUMENT } from '../../../routes';
import { createSas } from './api/createSas';
import { createStorageContainer } from './api/createStorageContainer';
import { uploadAdditionalDocuments } from './api/uploadAdditionalDocuments';

type Params = {
    connectionRequestId: string;
    processCommunicationId: string;
};

const acceptedFileExtensions = ['.pdf', '.jpg', '.jpeg', '.tiff', '.bmp'];

function DocumentUploadForm() {
    const { connectionRequestId, processCommunicationId } = useParams<Params>();

    const createStorageContainerApi = useApi(createStorageContainer);
    const createSasApi = useApi(createSas);
    const uploadAdditionalDocumentsApi = useApi(uploadAdditionalDocuments);

    const [folderName] = useState<string>(new Date().toISOString().replace(/:/g, '-'));
    const [combinedUploadState, setCombinedUploadState] = useState<CombinedUploadState>();
    const [privacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);
    const [showUploadError, setShowUploadError] = React.useState(false);

    const history = useHistory();

    const goToOfferRevisitedDocument = () =>
        history.push(generatePath(OFFER_REVISITED_DOCUMENT, { connectionRequestId, processCommunicationId }));

    const createSasCallback = useCallback(
        () => createSasApi({ processCommunicationId, connectionRequestId }),
        [createSasApi, processCommunicationId, connectionRequestId],
    );

    const createContainerCallback = useCallback(
        (): Promise<void> => createStorageContainerApi({ processCommunicationId, connectionRequestId }),
        [createStorageContainerApi, processCommunicationId, connectionRequestId],
    );

    const containerClientWrapper = useContainerClientWrapper({
        storageApiUrl: appConfig.services.connectionRequestFormUserUploadApi,
        containerName: `${processCommunicationId}-${connectionRequestId}`.toLowerCase(),
        createSas: createSasCallback,
        createStorageContainer: createContainerCallback,
        folderName,
    });

    const handleUploadedFiles = useCallback(
        (files: DataLessFileTransfer[], newCombinedUploadState: CombinedUploadState) => {
            setCombinedUploadState(newCombinedUploadState);
        },
        [],
    );

    const submitForm = async () => {
        try {
            await uploadAdditionalDocumentsApi({
                connectionRequestId,
                processCommunicationId,
                folderName,
                privacyPolicyAccepted,
            });
            goToOfferRevisitedDocument();
        } catch (error) {
            console.error('Failed to upload documents', error);
            setShowUploadError(true);
        }
    };

    const { prevButton, nextButton } = createProcessGuidanceButtons({
        isNextValid: privacyPolicyAccepted && combinedUploadState === 'success',
        onBack: goToOfferRevisitedDocument,
        onNext: submitForm,
        nextLabel: 'Absenden',
    });

    return (
        <div className="w-full flex flex-col gap-y-12 py-10 relative">
            <h3 className="font-bold text-medium">Dokumentenupload</h3>

            <span>
                Hier können Sie Dokumente hochladen und uns zur Verfügung stellen. Die folgenden Dateitypen sind möglich
                (jeweils max. 20 MB): PDF, JPEG, TIFF, BMP.
            </span>

            <SimpleDocumentUploadForm
                acceptedFileExtensions={acceptedFileExtensions}
                containerClientWrapper={containerClientWrapper}
                maxFileSizeMB={20}
                onChange={handleUploadedFiles}
            />

            <div className="flex justify-end space-x-3">
                Ich erkläre, dass ich die &nbsp;
                <StyledLink href="/files/DSI.pdf" download>
                    Datenschutzinformation
                </StyledLink>
                &nbsp; zur Kenntnis genommen habe.
                <ControlledCheckbox
                    data-testid="privacy-policy"
                    checked={privacyPolicyAccepted}
                    onChange={(val) => setIsPrivacyPolicyAccepted(val)}
                />
            </div>

            <div className="flex w-full justify-between">
                {prevButton}
                {nextButton}
            </div>
            {showUploadError && (
                <ErrorBox
                    onClick={() => setShowUploadError(false)}
                    text={[
                        'Beim Hochladen der Dokumente ist leider ein Fehler aufgetreten.',
                        'Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
                    ]}
                />
            )}
        </div>
    );
}

export default DocumentUploadForm;
