import {
    Button,
    CenteredLoadingSpinner,
    Checkbox,
    ErrorBox,
    Form,
    LoadingSpinnerButton,
    StyledLink,
} from '@ten-netzkundenportal/ui-components';
import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import AutomaticOffer from '../../../components/offer/AutomaticOffer';
import ManualOffer from '../../../components/offer/ManualOffer';
import useApi from '../../../hooks/useApi';
import { DASHBOARD } from '../../../routes';
import getOfferDownloadLink from '../../../util/getOfferDownloadLink';
import { sendOfferReply } from '../../api/OfferReplyAPI';
import { getOfferDocument } from '../../api/getOfferDocument';
import { getHint } from '../../util';
import { OfferRevisitedChildrenProps } from '../index';

type OfferCheckedRevisited = {
    isOfferAccepted: boolean;
    isAGBAccepted: boolean;
    areDocumentsAcknowledged: boolean;
};

const Conclusion = ({ mappedData, personalContribution }: OfferRevisitedChildrenProps) => {
    const history = useHistory();
    const [isSending, setIsSending] = useState(false);
    const [showError, setShowError] = useState<boolean>(false);
    const { connectionRequestId, processCommunicationId } = useParams();
    const getOfferDocumentApi = useApi(getOfferDocument);
    const sendOfferReplyApi = useApi(sendOfferReply);

    const {
        register,
        formState: { isValid },
        control,
        setValue,
    } = useForm<OfferCheckedRevisited>({
        mode: 'onChange',
        defaultValues: {
            isOfferAccepted: false,
            isAGBAccepted: false,
            areDocumentsAcknowledged: false,
        },
        shouldUnregister: true,
    });

    const isOfferAccepted = useWatch({ name: 'isOfferAccepted', control, defaultValue: false });

    const toggleOfferChecked = () => {
        setValue('isOfferAccepted', !isOfferAccepted);
    };

    const handleBack = () => {
        history.goBack();
    };
    const handleCancel = () => {
        window.location.href = DASHBOARD;
    };

    const handleSubmit = async () => {
        if (isValid) {
            const offerReplies = [
                {
                    offerCommunicationId: processCommunicationId,
                    acceptOffer: isOfferAccepted,
                },
            ];
            setIsSending(true);
            setShowError(false);

            try {
                await sendOfferReplyApi({
                    connectionRequestId,
                    offerReply: offerReplies,
                });
                window.location.href = DASHBOARD;
            } catch (error) {
                console.error(`Failed to send offer reply`, error);
                setShowError(true);
            } finally {
                setIsSending(false);
            }
        }
    };
    const openOfferDocument = async () => {
        const blob = await getOfferDocumentApi({
            processCommunicationId,
            connectionRequestId,
        });
        return getOfferDownloadLink(blob, `NAV_${processCommunicationId}.pdf`);
    };

    const prevButton = <Button type="secondary" label="Zurück" onClick={handleBack} />;
    const formatDate = (inputDate: string) =>
        new Date(inputDate).toLocaleDateString('de-DE', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        });

    if (!mappedData) {
        return <CenteredLoadingSpinner />;
    }

    return (
        <Form
            title="Unser Angebot an Sie"
            leftButton={prevButton}
            rightButton={
                <div className="flex justify-end gap-x-6">
                    <Button label="Abbrechen" onClick={handleCancel} type="secondary" />
                    <LoadingSpinnerButton
                        loadingTitle="Die Angebotsannahme wird übermittelt."
                        label="Zahlungspflichtig bestellen"
                        type="primary"
                        disabled={!isValid || !isOfferAccepted}
                        loading={isSending}
                        onClick={handleSubmit}
                        timeout={0}
                    />
                </div>
            }
        >
            <div className="-mb-8">
                Sie können Ihr Angebot nun annehmen. Wenn Sie noch Zeit zum Überlegen benötigen, ist das kein Problem.
                Das Angebot ist bis zum{' '}
                {formatDate(mappedData.manualOffer?.validUntil || mappedData.automaticOffer.validUntil)} gültig.
            </div>

            {mappedData?.manualOffer ? (
                <ManualOffer
                    disabled={false}
                    manualOffer={mappedData?.manualOffer}
                    checked={isOfferAccepted}
                    onClick={toggleOfferChecked}
                    title={mappedData?.branch === 'gas' ? 'Erdgas' : 'Strom'}
                    id={mappedData?.branch === 'gas' ? 'Erdgas' : 'Strom'}
                />
            ) : (
                <AutomaticOffer
                    disabled={false}
                    hint={getHint(mappedData?.branch)}
                    automaticOffer={mappedData?.automaticOffer}
                    checked={isOfferAccepted}
                    onClick={toggleOfferChecked}
                    title={mappedData?.branch === 'gas' ? 'Erdgas' : 'Strom'}
                    id={mappedData?.branch === 'gas' ? 'Erdgas' : 'Strom'}
                    personalContribution={personalContribution}
                />
            )}

            {isOfferAccepted && (
                <div className="w-full lg:w-10/12">
                    <div className="flex justify-between">
                        <label htmlFor="checkbox-isAGBChecked">
                            Mit dieser Bestellung akzeptiere ich die{' '}
                            <a href="/files/Nutzungsbedingungen.pdf" className="text-primary" download>
                                AGB
                            </a>
                            . Ferner erkläre ich, dass ich die{' '}
                            <a href="/files/DSI.pdf" className="text-primary" download>
                                Datenschutzinformation
                            </a>{' '}
                            zur Kenntnis genommen habe.
                        </label>
                        <Checkbox
                            name="isAGBAccepted"
                            {...register('isAGBAccepted', { required: true, shouldUnregister: true })}
                        />
                    </div>
                    <div className="flex justify-between">
                        <label htmlFor="areDocumentsAcknowledged">
                            Ich habe die Vertragsunterlagen{' '}
                            <StyledLink onClick={() => openOfferDocument()}>
                                {' '}
                                {mappedData.branch === 'electricity' ? 'Strom' : 'Erdgas'}{' '}
                            </StyledLink>
                            zur Kenntnis genommen.
                        </label>
                        <Checkbox
                            name="areDocumentsAcknowledged"
                            {...register('areDocumentsAcknowledged', { required: true, shouldUnregister: true })}
                        />
                    </div>
                </div>
            )}
            {showError && <ErrorBox onClick={() => setShowError(false)} />}
        </Form>
    );
};

export default Conclusion;
