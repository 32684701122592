import {
    FormItem,
    TeagPiktoEheizung800X800210729 as Heater,
    IconButton,
    RadioButton,
    StyledLink,
    TeagEmobilitaet,
    TeagPiktoWasser800X800210729 as Water,
} from '@ten-netzkundenportal/ui-components';
import '@ten-netzkundenportal/ui-components/dist/base.css';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { TwoButton, createPrevNextButton } from '../../../components';
import FormLayout from '../../../components/SignupForm/FormLayout';
import { TenPiktoSolarenergie } from '../../../icons';
import { PowerConsumptionUnknownProperties, UpdateContextFunction, UserTypeProperties } from '../../../types';
import { useContextUpdate, useDynamicDefault } from '../../../wizard';
import ChargingFields, { getDefaultValue as getDefaultValueForChargingFields } from './ChargingFields';
import ElectricalHeater, { getDefaultValue as getDefaultValueForElectricalHeater } from './ElectricalHeater';
import ElectricalWaterHeating, {
    getDefaultValue as getDefaultValueForElectricalWaterHeating,
} from './ElectricalWaterHeating';

export interface PowerRequirementsFormProps {
    onSubmit: () => void;
    goBack: () => void;
    updateContext: UpdateContextFunction;
    context: Partial<PowerConsumptionUnknownProperties & UserTypeProperties>;
}

function hasElement(container: unknown[]) {
    return Array.isArray(container) && container.length > 0;
}

function getDefaultValues(
    context: Partial<PowerConsumptionUnknownProperties & UserTypeProperties>,
): Partial<PowerConsumptionUnknownProperties> {
    const {
        electricHeater,
        electricService,
        electricWarmWater,
        electricalWaterHeating,
        chargingInfrastructure,
        extractionPlant,
        chargingStation,
    } = context;

    const defaultValues: Partial<PowerConsumptionUnknownProperties> = {
        electricHeater,
        electricWarmWater,
        extractionPlant,
        chargingStation,
    };

    defaultValues.electricService = hasElement(electricService)
        ? electricService
        : [getDefaultValueForElectricalHeater()];
    defaultValues.electricalWaterHeating = hasElement(electricalWaterHeating)
        ? electricalWaterHeating
        : [getDefaultValueForElectricalWaterHeating()];
    defaultValues.chargingInfrastructure = hasElement(chargingInfrastructure)
        ? chargingInfrastructure
        : [getDefaultValueForChargingFields()];

    return defaultValues;
}

export default function PowerConsumptionUnknown({
    onSubmit,
    goBack,
    updateContext,
    context,
}: PowerRequirementsFormProps): React.ReactElement {
    const defaultValues = getDefaultValues(context);
    const reactHookForm = useForm<PowerConsumptionUnknownProperties>({
        mode: 'all',
        shouldUnregister: true,
        defaultValues,
    });
    const {
        register,
        watch,
        handleSubmit,
        setValue,
        reset,
        formState: { isValid },
    } = reactHookForm;

    const electricHeater = watch('electricHeater', defaultValues.electricHeater);
    const electricWarmWater = watch('electricWarmWater', defaultValues.electricWarmWater);
    const chargingStation = watch('chargingStation', defaultValues.chargingStation);
    const extractionPlant = watch('extractionPlant', defaultValues.extractionPlant);

    useDynamicDefault(
        setValue,
        extractionPlant,
        'extractionPlantLessThan30',
        context.extractionPlantLessThan30 ?? 'yes',
    );
    useContextUpdate(watch, updateContext, 'CONSUMPTION_POWER_CONSUMPTION_DECLARATION');

    const optional = <span className="text-darkest-grey ml-1">(optional)</span>;

    const infrastructureLabel = <>Welche Infrastruktur werden Sie haben?{optional}</>;

    const infrastructureLabelInstaller = <>Welche Infrastruktur wird Ihr Kunde haben?{optional}</>;

    const [prevButton, nextButton] = createPrevNextButton({
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        isNextValid: isValid,
    });

    const toggleAreaFactory =
        (
            field: 'electricHeater' | 'electricWarmWater' | 'chargingStation' | 'extractionPlant',
            isTogglingOn: boolean,
            overrideValues?: Partial<PowerConsumptionUnknownProperties>,
        ) =>
        () => {
            const override = overrideValues ?? {};
            if (isTogglingOn)
                reset((values) => ({
                    ...values,
                    ...override,
                }));
            setValue(field, isTogglingOn);
            updateContext({ [field]: isTogglingOn }, 'CONSUMPTION_POWER_CONSUMPTION_DECLARATION');
        };

    return (
        <>
            <FormLayout>
                <FormItem
                    label={
                        context.userType === 'installer' || context.userType === 'projectpartner'
                            ? infrastructureLabelInstaller
                            : infrastructureLabel
                    }
                    className="items-center gap-x-4 grid grid-cols-[1fr_1fr_1fr_1fr]"
                >
                    <div className="w-full h-28">
                        <IconButton
                            name="heater"
                            isChecked={electricHeater}
                            iconSize="w-10"
                            labelVerticalOne="Elektrische Heizung"
                            icon={<Heater />}
                            onClick={toggleAreaFactory('electricHeater', !electricHeater, {
                                electricService: defaultValues.electricService,
                            })}
                            padding="1"
                        />
                    </div>
                    <div className="w-full h-28">
                        <IconButton
                            name="warm-water"
                            isChecked={electricWarmWater}
                            iconSize="w-10"
                            labelVerticalOne="Elektrische Warmwasserbereitung"
                            icon={<Water />}
                            onClick={toggleAreaFactory('electricWarmWater', !electricWarmWater, {
                                electricalWaterHeating: defaultValues.electricalWaterHeating,
                            })}
                            padding="1"
                        />
                    </div>
                    <div className="w-full h-28">
                        <IconButton
                            name="extraction-plant"
                            isChecked={extractionPlant}
                            iconSize="w-16 -mt-1"
                            labelVerticalOne="Erzeugungsanlage"
                            icon={<TenPiktoSolarenergie />}
                            onClick={toggleAreaFactory('extractionPlant', !extractionPlant)}
                            padding="1"
                        />
                    </div>
                    <div className="w-full h-28">
                        <IconButton
                            name="charging-station"
                            isChecked={chargingStation}
                            iconSize="w-10"
                            labelVerticalOne="Ladetechnik"
                            icon={<TeagEmobilitaet />}
                            onClick={toggleAreaFactory('chargingStation', !chargingStation, {
                                chargingInfrastructure: defaultValues.chargingInfrastructure,
                            })}
                            padding="1"
                        />
                    </div>
                </FormItem>
            </FormLayout>
            {electricHeater && <ElectricalHeater reactHookForm={reactHookForm} userType={context.userType} />}
            {electricWarmWater && <ElectricalWaterHeating reactHookForm={reactHookForm} userType={context.userType} />}
            {extractionPlant && (
                <div className="mt-5 sm:mt-0 relative">
                    <div className="grid grid-flow-col items-center">
                        <span className="font-bold">Erzeugungsanlage</span>
                    </div>
                    <FormLayout>
                        <FormItem
                            label={`Wird ${
                                context.userType === 'installer' || context.userType === 'projectpartner'
                                    ? 'die'
                                    : 'Ihre'
                            } Erzeugungsanlage eine Leistung kleiner 30 kW haben?`}
                            className="grid space-y-4"
                        >
                            <div className="w-[300px] flex space-x-20">
                                <RadioButton
                                    id="extractionPlantlessThan-yes"
                                    name="extractionPlantlessThan"
                                    value="yes"
                                    {...register('extractionPlantLessThan30', { shouldUnregister: true })}
                                >
                                    ja
                                </RadioButton>
                                <RadioButton
                                    id="extractionPlantlessThan-no"
                                    name="extractionPlantlessThan"
                                    value="no"
                                    {...register('extractionPlantLessThan30', { shouldUnregister: true })}
                                >
                                    nein
                                </RadioButton>
                            </div>

                            <span className="block text-small max-w-lg">
                                <b>Hinweis:</b> Bitte denken Sie an die separat erforderliche Anmeldung der
                                Erzeugungsanlage über den Bereich “Einspeisung“ im{' '}
                                <StyledLink href="/einspeisung/antragsstrecke/startseite" target="_blank">
                                    TEN Netzkundenportal
                                </StyledLink>{' '}
                                .
                            </span>
                        </FormItem>
                    </FormLayout>
                </div>
            )}
            {chargingStation && <ChargingFields reactHookForm={reactHookForm} userType={context.userType} />}
            <TwoButton leftButton={prevButton} rightButton={nextButton} />
        </>
    );
}
