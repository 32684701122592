import { InitializingMsalProvider } from '@ten-netzkundenportal/ui-auth-utils';
import { ErrorBoundary } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import { msalConfig } from './authentication.config';
import Root from './root.component';

const lifecycles = singleSpaReact({
    React,
    ReactDOMClient,
    rootComponent: () => (
        <React.StrictMode>
            <InitializingMsalProvider msalConfig={msalConfig}>
                <Root />
            </InitializingMsalProvider>
        </React.StrictMode>
    ),
    errorBoundary: ErrorBoundary,
});

export const { bootstrap, mount, unmount } = lifecycles;
