import React from 'react';

export interface SectionProps {
    title: string;
    children: React.ReactElement[] | React.ReactElement;
}

const HintBox = ({ title, children }: SectionProps): React.ReactElement => (
    <div className="flex-shrink-0 pb-4 shadow-ten w-full md:w-[30rem] mx-auto">
        <div className="p-4 font-bold text-center">{title}</div>
        <div className="sm:mx-16 mx-8 border-t-2 border-primary text-center" />
        {children}
    </div>
);

export default HintBox;
