import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Form, LoadingSpinnerButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { LOCATION_DATA, MAINTENANCE, START } from '../routes';
import { isMaintenanceActive } from '../util/maintenance';
import HintBox from './component/HintBox';

const Start = (): React.ReactElement => {
    const { instance } = useMsal();
    const history = useHistory();

    const [startButtonLoading, setStartButtonLoading] = React.useState(false);

    const handleStartButtonClick = async () => {
        setStartButtonLoading(true);
        localStorage.removeItem('antragsstrecke');
        if (await isMaintenanceActive()) {
            history.push(MAINTENANCE);
        } else {
            history.push(LOCATION_DATA);
        }
        setStartButtonLoading(false);
    };

    return (
        <Form title="Netzanschluss">
            <div id="explanation">
                <span>
                    Sie brauchen einen neuen Netzanschluss für Strom oder Erdgas und wünschen schnell und unkompliziert
                    ein persönliches Angebot? Dann sind Sie hier genau richtig!
                </span>
                <br />
                <span>
                    Es sind keine Dokumente von Ihnen erforderlich, Ihre Daten können Sie alle direkt eingeben bzw.
                    werden für Sie ermittelt.
                </span>
            </div>

            <div className="flex flex-col gap-y-4">
                <span className="font-bold">Welche Informationen benötige ich, um ein Angebot anzufordern?</span>
                <ul className="teagTickList ml-6 flex flex-col gap-y-4">
                    <li>
                        <span className="font-bold">Standort</span> des Anschlussobjekts (Adresse oder Flurstückdaten)
                    </li>
                    <li>
                        Ihren <span className="font-bold">Leistungsbedarf</span> oder alternativ den{' '}
                        <span className="font-bold">Planungsstand</span> für Ihre{' '}
                        <span className="font-bold">Infrastruktur</span>
                    </li>
                    <li>
                        <span className="font-bold">Planungsstand</span> für den benötigen{' '}
                        <span className="font-bold">Anschluss</span> (wo soll der Anschluss gesetzt werden, wo soll die
                        Leitung verlaufen usw.)
                    </li>
                </ul>
            </div>

            <UnauthenticatedTemplate>
                <div className="gap-y-4">
                    <div>
                        <span className="font-bold">Sie sind schon im Netzkundenportal registriert?</span>
                    </div>
                    <div>
                        <br />
                        <span>
                            Wenn Sie schon in unserem Netzkundenportal registriert sind, empfehlen wir Ihnen, sich für
                            Ihren Antrag{' '}
                            <button
                                className="text-primary"
                                type="button"
                                onClick={() => {
                                    window.sessionStorage.setItem('portal-app-local-redirect-url', START);
                                    instance.loginRedirect();
                                }}
                            >
                                anzumelden
                            </button>
                            .
                        </span>
                        <br />
                        <span>
                            Sie sind noch nicht im Portal angemeldet? Kein Problem – eine schnelle{' '}
                            <a className="text-primary" href="/registrierung">
                                Registrierung
                            </a>{' '}
                            ist jetzt oder innerhalb Ihres Antrags möglich, so dass Sie nach Abschluss Ihren Antrag
                            jederzeit einsehen können. Zudem können Sie im Portal Ihre Daten selbstständig anpassen, die
                            Bearbeitung verfolgen und Kontakt zu uns aufnehmen.
                        </span>
                    </div>
                </div>
            </UnauthenticatedTemplate>

            <div className="flex justify-center my-8">
                <LoadingSpinnerButton
                    label="Los geht's"
                    loading={startButtonLoading}
                    onClick={handleStartButtonClick}
                    timeout={0}
                />
            </div>

            <div
                id="hints"
                className="flex flex-col lg:flex-row justify-center gap-y-4 gap-x-4 xl:gap-x-20 items-stretch pb-10"
            >
                <HintBox title="Tipps für Privat- und Firmenkunden">
                    <ul className="teagTickList p-8 flex flex-col gap-y-6">
                        <li>
                            <span>Stimmen Sie die Anschlussplanung mit Ihrem Installateur im Vorfeld ab.</span>
                        </li>
                        <li>
                            <span>
                                Lesen Sie unsere{' '}
                                <a
                                    href="https://www.thueringer-energienetze.com/Content/Documents/Anschluss/TEN_Bauherrenbroschuere.pdf"
                                    className="text-primary"
                                    target="_blank"
                                    rel="noreferrer"
                                    download
                                >
                                    Bauherrenbroschüre
                                </a>
                                .
                            </span>
                        </li>
                    </ul>
                </HintBox>
                <HintBox title="Tipps für Installateure und Projektpartner">
                    <div className="flex flex-col p-8 sm:p-8 gap-y-2">
                        <div className="flex flex-col gap-y-2">
                            <span>
                                Beachten Sie bitte, dass Ihr Kunde bereits im Netzkundenportal registriert sein muss.
                            </span>
                            <span>Erfragen Sie vorab folgende Informationen:</span>
                        </div>
                        <ul className="teagTickList flex flex-col gap-y-4">
                            <li>
                                <span>Einwilligung Ihres Kunden, dass Sie den Antrag stellen dürfen</span>
                            </li>
                            <li>
                                <span>Anschrift und gewünschte Rechnungsadresse</span>
                            </li>
                            <li>
                                <span>E-Mail-Adresse Ihres Kunden</span>
                            </li>
                        </ul>
                    </div>
                </HintBox>
            </div>
        </Form>
    );
};

export default Start;
