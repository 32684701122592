/**
 * Source: https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/blob/main/3-Authorization-II/2-call-api-b2c/SPA/src/authConfig.js
 *
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
import appConfig from './app.config';

export const b2cPolicies = {
    names: {
        signUpSignIn: `${appConfig.userFlowName}`,
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${appConfig.tenantName}.b2clogin.com/${appConfig.tenantName}.onmicrosoft.com/${appConfig.userFlowName}`,
        },
    },
    authorityDomain: `${appConfig.tenantName}.b2clogin.com`,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
    auth: {
        // This is the ONLY mandatory field that you need to supply.
        clientId: appConfig.tenantApplicationClientId,
        // Choose SUSI as your default authority.
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        // Mark your B2C tenant's domain as trusted.
        knownAuthorities: [b2cPolicies.authorityDomain],
        // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        redirectUri: '/redirect',
        // Indicates the page to navigate after logout.
        postLogoutRedirectUri: '/',
        // If "true", will navigate back to the original request location before processing the auth code response.
        navigateToLoginRequestUrl: false,
    },
    cache: {
        // Configures cache location. "sessionStorage" is more secure,
        // but "localStorage" gives you SSO between tabs.
        cacheLocation: 'localStorage',
        // Set this to "true" if you are having issues on IE11 or Edge
        storeAuthStateInCookie: false,
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [appConfig.tenantApplicationClientId],
};
