import { useMsal } from '@azure/msal-react';
import { acquireAccessToken } from '@ten-netzkundenportal/ui-auth-utils';
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import React from 'react';

import { loginRequest } from '../authentication.config';

const useAuthenticatedHttpClient = (): AxiosInstance => {
    const { instance, accounts } = useMsal();

    return React.useMemo(() => {
        const httpClient = axios.create();
        httpClient.interceptors.request.use(
            async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
                const token = await acquireAccessToken(instance, accounts[0], loginRequest.scopes);
                const headers = { ...config.headers, Authorization: `Bearer ${token}` };
                return { ...config, headers } as InternalAxiosRequestConfig;
            },
        );

        return httpClient;
    }, [accounts, instance]);
};
export default useAuthenticatedHttpClient;
